import trackerCache from '@/cache/tracker.cache.js';
import cdiLocationCache from '@/cache/cdiLocation.cache';
import DateHelpers from "@/store/DateHelpers";

export const trackerMerStore = {
	state: {
		name: 'tracker_mer',
		title: 'MER',
		titlePlural: 'MERS',
		nav: {
			base: 'tracker_mer',
			newView: false,
			views: [
				{ view: 'details', label: 'Details', },
				{ view: 'notebook', label: 'Notebook', },
				{ view: 'history', label: 'History', },
			],
		},
		search: {
			endPoint: 'tracker_mers',
			formFields: {
				dateFrom: {
					val: DateHelpers.getXMonthsAgo(1),
					qsField: '[dates][tm.date_issued_from]',
					label: 'MER Date From',
					component: 'SearchDateInput'
				},
				dateTo: {
					val: DateHelpers.getToday(),
					qsField: '[dates][tm.date_issued_to]',
					label: 'MER Date To',
					component: 'SearchDateInput',
				},
				trackerMerID: {
					val: '',
					qsField: '[numbers][tm.tracker_mer_id]',
					label: 'MER ID',
					component: 'SearchTextInput',
				},
				trackerID: {
					val: '',
					qsField: '[numbers][tm.tracker_id]',
					label: 'Order ID',
					component: 'SearchTextInput',
				},
				customerName: {
					val: '',
					qsField: '[numbers][u.user_id]',
					label: 'Customer Name',
					component: 'SearchTypeaheadInput',
					cdiType: 'user',
				},
				companyName: {
					val: '',
					qsField: '[numbers][c.company_id]',
					label: 'Company Name',
					component: 'SearchTypeaheadInput',
					cdiType: 'company',
				},
				cdiLocationID: {
					val: 0,
					qsField: '[numbers][t.cdi_location_id]',
					label: 'CDI Location',
					component: 'SearchSelectInput',
					options: cdiLocationCache.CDI_LOCATIONS,
				},
				type: {
					val: 0,
					qsField: '[numbers][tm.fault_group_id]',
					label: 'Type',
					component: 'SearchSelectInput',
					options: trackerCache.TRACKER_MER_REMAKE_TYPES,
				},
				cause: {
					val: 0,
					qsField: '[numbers][tm.cause_id]',
					label: 'Cause',
					component: 'SearchSelectInput',
					options: trackerCache.CAUSE_TYPES_HISTORICAL
				},
				resolution: {
					val: 0,
					qsField: '[numbers][tm.resolution_id]',
					label: 'Resolution',
					component: 'SearchSelectInput',
					options: trackerCache.TRACKER_MER_RESOLUTIONS
				},
				salesSecondary: {
					val: 0,
					qsField: '[numbers][tm.secondary_sales_id]',
					label: 'Sales Secondary',
					component: 'SearchSelectInput',
					options: trackerCache.TRACKER_MER_SECONDARY_SALES,
					toggleHide: true,
					defaultVal: 0,
				},
				designSecondary: {
					val: 0,
					qsField: '[numbers][tm.secondary_design_id]',
					label: 'Design Secondary',
					component: 'SearchSelectInput',
					options: trackerCache.TRACKER_MER_SECONDARY_DESIGN,
					toggleHide: true,
					defaultVal: 0,
				},
				operationsSecondary: {
					val: 0,
					qsField: '[numbers][tm.secondary_operations_id]',
					label: 'Operations Secondary',
					component: 'SearchSelectInput',
					options: trackerCache.TRACKER_MER_SECONDARY_OPERATIONS,
					toggleHide: true,
					defaultVal: 0,
				},
				creditPending: {
					val: '',
					qsField: '[isset][tm.credit_pending]',
					label: 'Pending Credit Only',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				cdiIssueClosed: {
					val: 1,
					qsField: '[isnot][tm.cdi_issue_closed]',
					label: 'Open CDI Issue',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: 1,
				},
				customerIssueClosed: {
					val: 1,
					qsField: '[isnot][tm.customer_issue_closed]',
					label: 'Open Customer Issue',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: 1,
				},
				pageSize: {val: 24, qsField: '[page_size]'},
				searchIndex: {val: 0, qsField: '[search_index]'},
				direction: {val: 'ASC', qsField: '[direction]'},
				orderBy: {val: 'tracker_mer_id', qsField: '[orderby]'},
			},
			resultFields: {
				tracker_mer_id: {header: 'MER', link: 'tracker_mer'},
				company_name: {header: 'Company Name', link: ''},
				customer_name: {header: 'Customer Name', link: ''},
				date_issued: {header: 'Date Issued', link: ''},
				cause: {header: 'Cause', link: ''},
				fault_group: {header: 'Type', link: ''},
				resolution: {header: 'Resolution', link: ''},
				credit_pending: {header: 'Pending Credit', link: ''},
				final_cost_to_cdi: {header: 'Final Cost', link: ''}
			}
		},
		object: {
			fields: [
				{
					name: 'tracker_id',
					label: 'Order',
					component: 'DetailsButton',
					link: 'tracker'
				},
				{
					name: 'date_issued',
					label: 'Date Issued',
					component: 'DetailsStatic',
				},
				{
					name: 'fault_group_id',
					label: 'Type',
					component: 'DetailsSelect',
					options: trackerCache.TRACKER_MER_REMAKE_TYPES
				},
				{
					name: 'cause_id',
					label: 'Cause',
					component: 'DetailsSelect',
					options: trackerCache.TRACKER_MER_CAUSE_TYPES
				},
				{
					name: 'secondary_sales_id',
					label: 'Sales Secondary',
					component: 'DetailsSelect',
					options: trackerCache.TRACKER_MER_SECONDARY_SALES
				},
				{
					name: 'secondary_design_id',
					label: 'Design Secondary',
					component: 'DetailsSelect',
					options: trackerCache.TRACKER_MER_SECONDARY_DESIGN
				},
				{
					name: 'secondary_operations_id',
					label: 'Operations Secondary',
					component: 'DetailsSelect',
					options: trackerCache.TRACKER_MER_SECONDARY_OPERATIONS
				},
				{
					name: 'cdi_location',
					label: 'Location',
					component: 'DetailsStatic'
				},
				{
					name: 'issue_description',
					label: 'Issue Description',
					component: 'DetailsTextarea'
				},
				{
					name: 'misc_description',
					label: 'Misc Description',
					component: 'DetailsTextarea'
				},
				{
					name: 'cdi_fix_description',
					label: 'Fix Description',
					component: 'DetailsTextarea'
				},
				{
					name: 'contact_information',
					label: 'Contact',
					component: 'DetailsText'
				},
				{
					name: 'cdi_estimated_cost',
					label: 'CDI Total Cost',
					component: 'DetailsText'
				},
				{
					name: 'customer_estimated_cost',
					label: 'Customer Est Cost',
					component: 'DetailsText'
				},
				{
					name: 'credit_approval_date',
					label: 'Credit Approval Date',
					component: 'DetailsDate'
				},
				{
					name: 'credit_id',
					label: 'Credit',
					component: 'DetailsButton',
					buttonLabel: 'Create',
					endPoint: 'issue_credit',
					link: 'credit',
				},
				{
					name: 'credit_pending',
					label: 'Pending Credit',
					component: 'DetailsCheckbox'
				},
				{
					name: 'cdi_issue_closed',
					label: 'MER Closed',
					component: 'DetailsCheckbox'
				},
				{
					name: 'resolution_id',
					label: 'Resolution',
					component: 'DetailsSelect',
					options: trackerCache.TRACKER_MER_RESOLUTIONS
				},
				{
					name: 'field_fault',
					label: 'Field Issue',
					component: 'DetailsCheckbox'
				},
				{
					name: 'final_cost_to_cdi',
					label: 'Credit Amount',
					component: 'DetailsText'
				},
				{
					name: 'company_name',
					label: 'Company Name',
					component: 'DetailsText'
				},
				{
					name: 'customer_name',
					label: 'Customer Name',
					component: 'DetailsText'
				},
			],
		},
	},
}